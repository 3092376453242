import "../styles.css";
import React from "react";
import { LangContext } from "../context/LangContext";
import { useLang } from "../hooks/useLang";

export const RootWrapper = ({ children }) => {
  const [lang, toggleLang] = useLang();

  return (
    <LangContext.Provider value={{ lang, toggleLang }}>
      {children}
    </LangContext.Provider>
  );
};
