import { useState } from "react";

export const useLang = () => {
  const DEFAULT_LANG = "cs";

  const [lang, setLang] = useState(DEFAULT_LANG);

  const toggleLang = () => {
    setLang(lang === "en" ? "cs" : "en");
  };

  return [lang, toggleLang];
};
